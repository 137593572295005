import { useHistory } from "react-router";
import "./style.css";

const ErrorPage = () => {
    const history = useHistory();
    return (
        <div className="error">
            <div className="error-text">
                <h1>Error 404 Page Not Found</h1>
                <p>
                    Redirect to
                    <span>
                        <a
                            onClick={() => {
                                history.push("/");
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            &nbsp;home
                        </a>
                    </span>
                </p>
            </div>
        </div>
    );
};

export default ErrorPage;
