import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Routes from './routes'
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ErrorPage from './Components/404';

function App() {
  return (
    <BrowserRouter>
        <Switch>
          <Route path="/404" exact component={ErrorPage}></Route>
          <Route path="/" component={Routes}></Route>
        </Switch>
    </BrowserRouter>
  );
}

export default App;
