import React, { useEffect } from "react";
import ServiceDescription from "./ServiceDescription";
import Contact from "../Contact";
import FAQ from "./FAQ";
import Work from './Work';
import serviceList from './services-list';
import "./style.css";
import {Helmet} from 'react-helmet';

const Service = ({match}) => {

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  let service = serviceList.find(e => e.id === match.params.service);

  return (
    <div className="Service">
      <Helmet>
        <title>{service.header}</title>
        <meta name="description" content={service.desc}/>
        <meta property="og:title" content={service.header}/>
        <meta property="og:description" content={service.desc}/>
        <meta property="og:url" content={`https://www.ngdentalclinic.com/services/${match.params.service}`}/>
      </Helmet>
      <div className="container-fluid divider">
        <ServiceDescription
          ServiceName={service.name}
          Description={service.desc}
          main={service.main}
          hr={service.hr}
          animationId={service.serviceDescriptionId}
        ></ServiceDescription>
      </div>
      {/* <div className="FunFactsSection">
        <h2>Fun facts</h2>
        <FunFacts
        ff={service.ff}
        ></FunFacts>
      </div> */}
      <div className="divider">
        <FAQ
        q={service.q}
        a={service.a}
        >
        </FAQ>
      </div>
      <div className="divider">
        <Work work={service.work} animationId={service.workId}></Work>
      </div>
      {/* TODO: change according to images added. Add same number of images to all Our works */}
      <Contact></Contact>
    </div>
  );
};

export default Service;
