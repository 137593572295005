import React, { useEffect } from "react";
import "./style.css";
import DoctorRight from "./DoctorRight";
import DoctorLeft from "./DoctorLeft";
import OurStory from "./OurStory";
import Contact from '../Contact';
import Facilities from './Facilities';
import Nurses from './Nurses';
import {Helmet} from 'react-helmet';

const About = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about">
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="Ng Dental Surgery was established in 1986 by Dr. Ng and Dr. See who
              graduated from the reputable and prestigious University of Malaya
              (UM). They were then later joined by a consultant orthodontist, Dr.
              Chia. Today, the clinic is fully equipped with the latest equipments
              ranging from 3D tooth scanning machine to full mouth radiography."/>
        <meta property="og:title" content="About Us"/>
        <meta property="og:description" content="Ng Dental Surgery was established in 1986 by Dr. Ng and Dr. See who
              graduated from the reputable and prestigious University of Malaya
              (UM). They were then later joined by a consultant orthodontist, Dr.
              Chia. Today, the clinic is fully equipped with the latest equipments
              ranging from 3D tooth scanning machine to full mouth radiography."/>
        <meta property="og:url" content="https://www.ngdentalclinic.com/about"/>
      </Helmet>
      <div className="container-fluid">
        <OurStory></OurStory>
      </div>
        <DoctorLeft
          name="Dr. Ng Sang Hee"
          specialisation="Dental Surgeon"
          image ="assets/doctor/doctor_1.jpg"
          animationId = "about-left-doctor-1"
          hr="8rem"
          facts={[
            "Graduated from UM in 1986",
            `A dedicated surgeon with extensive experience and knowledge of dental procedures ranging from 
            dental restorations, crowns & bridgeworks, implants, dentures, tooth whitening, root canal treatments and more`,
            `Vast experience in handling all kinds of dental machines ranging from the latest
            dental tools to digital 3D imaging of the teeth`,
            "35 years of experience"
          ]}
        ></DoctorLeft>
        <DoctorRight
          name="Dr. See Gek Ding"
          specialisation="Dental Surgeon"
          image ="assets/doctor/doctor_2.jpg"
          animationId = "about-left-doctor-2"
          hr="8rem"
          facts={[
            "Graduated from UM in 1988",
            `A dedicated hard working dental surgeon with vast experience 
            in oral diagnosis, prosthodontics, restorative work and more`,
            "Adept in providing the highest level of care to the patients and pain management",
            "33 years of experience"
          ]}
        ></DoctorRight>
        <DoctorLeft
          name="Dr. Chia Yang Soon"
          specialisation="Consultant Orthodontist"
          image ="assets/doctor/doctor_3.jpg"
          animationId = "about-left-doctor-3"
          hr="12rem"
          facts={[
            "Graduated from UM in 1988 with BDS",
            `Postgraduate study in Orthodontics at Eastman Dental Institude, University of 
            London Orthodontics 1997-2000 with MSc (Ortho)`,
            "MOrth.RCS. (Edin), MOrth.RCS (Lond & Glasgow)",
            "Member of the Academy of Medicine, Malaysia",
            "Gazetted as specialist at National Specialist Register 2012",
            "Excellent skills in treating orthodontic patients ranging from simple to complex cases",
            "33 years of experience"
          ]}
        ></DoctorLeft>
        <Nurses></Nurses>
        <Facilities></Facilities>
      <Contact scroll="3400"></Contact>
    </div>
  );
};

export default About;
