import React, { useEffect } from "react";
import "./style.css";
import DoctorProfile from './DoctorProfile'
import Services from './Services';
import Contact from '../Contact'
import Reviews from './Reviews'
import NgDental from './NgDental';
import Qualities from './Qualities';
import {Helmet} from 'react-helmet'

const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="Home">
      <Helmet>
        <title>Ng Dental Surgery Klang</title>
        <meta name="description" content="Ng Dental Surgery - Taman Gembira, Klang. Established since 1986 by Dr.Ng and Dr. 
        See who graduated from the reputable and prestigious University of Malaya (UM)."/>
        <meta property="og:title" content="Ng Dental Surgery Klang"/>
        <meta property="og:description" content="Ng Dental Surgery - Taman Gembira, Klang. Established since 1986 by Dr.Ng and Dr. See who graduated from the reputable and prestigious University of Malaya (UM)."/>
        <meta property="og:url" content="https://www.ngdentalclinic.com"/>
      </Helmet>
      <NgDental></NgDental>
      <div className="divider">
        <Qualities></Qualities>
      </div>
      <h2>
          Meet our team!
      </h2>
      <p>
        A team of dedicated and professional dentists. 
      </p>
      <div className="row justify-content-center" style={{paddingLeft:'2.5%',paddingRight:'2.5%'}}>
        <DoctorProfile 
          img='assets/doctor/doctor_1.jpg' 
          name="Dr. Ng Sang Hee"  
          specialisation="Dental Surgeon" 
          animation="left"
          animationId="doctor-profile-1" 
          to="/about"
        />
        <DoctorProfile 
          img='assets/doctor/doctor_2.jpg' 
          name="Dr. See Gek Ding" 
          specialisation="Dental Surgeon" 
          animation="middle" 
          animationId="doctor-profile-2" 
          to="/about"
        />
        <DoctorProfile 
          img='assets/doctor/doctor_3.jpg' 
          name="Dr. Chia Yang Soon" 
          specialisation="Consultant Orthodontist" 
          animation="right" 
          animationId="doctor-profile-3" 
          to="/about"
        />
      </div>
      <h2>Our Services</h2>
      <hr style={{width:'8rem', marginTop:'0px', border:'0',borderBottom:'2px solid grey'}}/>
      <div className="divider">
        <Services></Services>
      </div>
      <div className="divider">
        {/* <ContactBanner></ContactBanner> */}
        <Reviews></Reviews>
      </div>
      <div className="divider">
        <Contact animationId="home-contact"></Contact>
      </div>
    </div>
  );
};

export default Home;
