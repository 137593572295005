import {React, useEffect, useState} from 'react';
import { useHistory } from 'react-router';
import './style.css'
import {Helmet} from 'react-helmet'

const Contact = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const [classState, setClassState] = useState("contact-start");
    const [helmet, setHelmet] = useState(null);
    const history = useHistory();

    useEffect(() => {
        setClassState("contact-start");
        
        let listener = document.addEventListener("scroll", (e) => {
            let pos = document.getElementById('contact-id');
            if (pos && pos.getBoundingClientRect().top <= window.innerHeight*3/4) {
            setClassState("contact-slide");
            }
        });
        return () => {
            document.removeEventListener("scroll", listener);
        };
    }, [history.location.pathname]);

    
    useEffect(()=>{
        if(history.location.pathname==="/contact"){
            setHelmet(
                <Helmet>
                    <title>Contact Us</title>
                    <meta name="description" content="Klinik Pergigian Ng, 2578A,
                    Persiaran Raja Muda Musa,
                    Taman Gembira,
                    41100 Klang, Selangor"/>
                    <meta property="og:title" content="Contact Us"/>
                    <meta property="og:description" content="Klinik Pergigian Ng, 2578A,
                    Persiaran Raja Muda Musa,
                    Taman Gembira,
                    41100 Klang, Selangor"/>
                    <meta property="og:url" content="https://www.ngdentalclinic.com/contact"/>
                </Helmet>
            )
        }
    })

    return (
        <div className="contact divider">
            {helmet}
            <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-4 divider">
                    <div className="office">
                        <h2>
                            <strong>
                                Our office
                            </strong>
                        </h2>
                        <hr style={{width:'5rem', marginTop:'0', marginLeft:'0', border:'0',borderBottom:'2px solid grey'}}/>
                    </div>
                    <div className="office">
                        <p>
                            Klinik Pergigian Ng, 2578A, 
                            <br/>
                            Persiaran Raja Muda Musa,
                            <br/>
                            Taman Gembira,
                            <br/>
                            41100 Klang, Selangor
                            <br/>
                        </p>
                    </div>
                    <div className="call">
                        <h3>
                            <a href="tel:+60333721099">
                                <i className="fas fa-phone-alt" style={{marginRight:'10px', width:'21px'}}></i>
                                    03-3372 1099
                            </a>
                        </h3>
                        {/* <h3>
                            <a href="https://wa.me/60163349669"
                            target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-whatsapp fa-lg" style={{marginRight:'10px', width:'21px'}}></i>
                                    012-345 6789
                            </a>
                        </h3>  */}
                    </div>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-4 divider">
                    <div className="hours">
                        <h2>
                            <strong>
                                Hours
                            </strong>
                        </h2>
                        <hr style={{width:'5rem', marginTop:'0%', marginLeft:'0', border:'0',borderBottom:'2px solid grey'}}/>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="day">
                                <p>
                                        Monday
                                        <br/>
                                        Tuesday
                                        <br/>
                                        Wednesday
                                        <br/>
                                        Thursday
                                        <br/>
                                        Friday
                                        <br/>
                                        Saturday
                                        <br/>
                                        Sunday/PH  
                                        <br/>
                                        Lunch time
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="time">
                                <p>
                                        9AM - 5PM
                                        <br/>
                                        9AM - 5PM
                                        <br/>
                                        9AM - 5PM
                                        <br/>
                                        9AM - 5PM
                                        <br/>
                                        9AM - 5PM
                                        <br/>
                                        9AM - 5PM
                                        <br/>
                                        Closed
                                        <br/>
                                        1PM - 2PM
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-1">
                </div>
                {/* <div className="col-lg-4 divider">
                    <div className="shop-lot">
                        <img 
                        alt="shop"
                        src="/assets/contact/shop_lot.jpg" 
                        width="100%" 
                        height="100%" 
                        className={classState}
                        id='contact-id'
                        ></img>
                    </div>
                </div> */}
            </div>
            <div className="row">
                <iframe title="location" className="Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.2450175310305!2d101.42643021475702!3d3.028781597794288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdab572be38e15%3A0xc516749e2d0c3244!2sNg%20Dental%20Surgery!5e0!3m2!1sen!2smy!4v1608735466868!5m2!1sen!2smy"></iframe>
            </div>
        </div>
    )
}

export default Contact