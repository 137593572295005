const home = '/assets/';

const cardInfo = [
  {
    image: home + 'rct.jpg',
    title: 'Root Canal Treatment',
    route: '/services/rct'
  },
  {
    image: home + 'braces.jpg',
    title: 'Orthondontic Treatment',
    route: '/services/braces'
  },
  {
    image: home + 'crowns.jpg',
    title: 'Crowns & Bridges',
    route: '/services/crowns'
  },
  {
    image: home + 'dentures.jpg',
    title: 'Dentures',
    route: '/services/dentures'
  },
  {
    image: home + 'implants.jpg',
    title: 'Implants',
    route: '/services/implants'
  },
  {
    image: home + 'veneers.jpg',
    title: 'Veneers',
    route: '/services/veneers'
  },
  {
    image: home + 'whitening.jpg',
    title: 'Tooth Whitening',
    route: '/services/whitening'
  },
  {
    image: home + 'surgery.jpg',
    title: 'Extractions & Surgery',
    route: '/services/extractions'
  },
  {
    image: home + 'fillings.png',
    title: 'Fillings',
    route: '/services/fillings'
  },
  
];

export default cardInfo;
