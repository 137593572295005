import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "./style.css";

const ServiceDescription = (props) => {
  const [classState, setClassState] = useState("service-start");

  const history = useHistory();

  useEffect(() => {
    setClassState("service-start");
    let listener = document.addEventListener("scroll", (e) => {
      let pos = document.getElementById(props.animationId)
      if (pos && pos.getBoundingClientRect().top <= window.innerHeight*3/4) {
        setClassState("service-slide");
      }
    });
    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, [history.location.pathname]);

  return (
    <div className="ServiceDescription">
      <div className="row row-flex">
        <div className="col-lg-7 col-flex-item divider">
          <h2>{props.ServiceName}</h2>
          <hr
            style={{
              width: '6rem',
              marginTop: "0%",
              marginLeft: "0",
              border: "0",
              borderBottom: "2px solid grey",
              borderBottomColor: "#005163",
            }}
          />
          <p>{props.Description}</p>
        </div>
        <div className="col-lg-5 col-flex-item">
          <img
            alt="service description"
            src={props.main}
            className={classState}
            id={props.animationId}
            style={{
              width: "100%",
              height: "100%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          ></img>
        </div>
      </div>
    </div>
  );
};

export default ServiceDescription;
