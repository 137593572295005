import React, { useEffect, useState } from "react";
import "./style.css";

const NgDental = () => {
  const [classState, setClassState] = useState("ng-dental-start");

  useEffect(() => {
    let listener = document.addEventListener("scroll", (e) => {
      let pos = document.getElementById('ng-dental');
      if (pos && pos.getBoundingClientRect().top <= window.innerHeight*3/4) {
        setClassState("ng-dental-animate");
      }
    });
    return () => {
      document.removeEventListener("scroll", listener);
    };
  });

  return (
    <div className="ng-dental">
      <div className="row row-flex">
        <div className="col-lg-7 col-flex-item divider">
          <h2>Welcome to Ng Dental Surgery</h2>
          <hr
            style={{
              width: "6rem",
              marginTop: "0%",
              marginLeft: "0%",
              border: "0",
              borderBottom: "2px solid grey",
              borderBottomColor: "#005163",
            }}
          />
          <p>
            At Ng Dental, we are all about putting you at ease and helping you
            achieve the best oral health possible. Here, we offer a wide range
            of dental services such as extractions, braces, whitening, and many more.
            Our patient's comfort is our number one priority and we aim to make your visit
            to our clinic a pleasant experience.
            We will use our many years of experience and acquired skills to help you create your dream smile.

          </p>
        </div>
        <div className="col-lg-5 col-flex-item divider">
          <img
            alt="Ng Dental"
            src="assets/home/1.jpg"
            className={classState}
            id='ng-dental'
            style={{
              width: "100%",
              height: "100%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          ></img>
        </div>
      </div>
    </div>
  );
};

export default NgDental;
