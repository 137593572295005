import React from 'react';
import './style.css'

const Qualities = () => {
    return(
        <div className="qualities divider">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="star">
                            &#9733;
                        </div>
                        <div className="quality">
                            <p>
                                Quality care dental clinic since 1986
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="star">
                            &#9733;
                        </div>
                        <div className="quality">
                            <p>
                                Multi-disciplinary dental clinic
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="star">
                            &#9733;	
                        </div>
                        <div className="quality">
                            <p>
                                Well maintained modern facilities
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="star">
                            &#9733;
                        </div>
                        <div className="quality">
                            <p>
                                Experienced dental surgeons and specialists
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Qualities