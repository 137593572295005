const serviceList = [
    {
        id: 'rct',
        header: 'Root Canal Treatment', 
        name: 'Get to the root of your toothache',    
        desc: `Root canal treatment lets you save your tooth instead of extracting it.
        What is a root canal? It is when the nerves of your tooth get infected which could lead to serious problems.
        A root canal treatment can help relieve you of your toothache.`,
        bg: 'rct-bg',
        main: '/assets/rct/main.jpg',
        hr: '26rem',
        serviceDescriptionId: 'service-description-1',
        workId: 'service-work-1',
        work:
        [
            {
                image:'/assets/rct/1.png',
            },
            {
                image:'/assets/rct/2.png',
            },
            {
                image:'/assets/rct/3.png',
            },
            {
                image:'/assets/rct/4.png',
            },
            {
                image:'/assets/rct/5.png',
            },
            {
                image:'/assets/rct/6.png',
            }
        ],
        q: 
        [
            'How long will the treatment take?',  
            'What are my other options?', 
            'How much will it cost?',
        ],
        a:
        [
            'Ranges from 30 minutes to 1.5 hours, depending on the number of canals and level of difficulty',
            'Extractions, implants, dentures or bridges',
            'Depending on the number of canals',
        ]
    },
    {
        id: 'braces',
        header: 'Orthodontic Treatment',  
        name: 'Crooked teeth no more',  
        desc: `Ever wanted straighter teeth? Have aesthetic concerns or biting problems?
        Orthodontic treatment helps restore close-to-ideal bite, improve aesthetics.
        Here at Ng Dental we provide excellent orthodontic treatment to help align and straighten your teeth. `,
        bg: 'rct-bg',
        main: '/assets/braces/main.jpg',
        hr: '28rem',
        serviceDescriptionId: 'service-description-2',
        workId: 'service-work-2',
        work:
        [
            {
                image:'/assets/braces/1.png',
            },
            {
                image:'/assets/braces/2.png',
            },
            {
                image:'/assets/braces/3.png',
            },
            {
                image:'/assets/braces/4.png',
            }
        ],
        q: 
        [
            'How long will the treatment take?', 
            'Will braces hurt?', 
            'How much will it cost?', 
        ],
        a:
        [
            'Usually 24 to 36 months. Some may require less than 12 months and there are also patients that require more than 3 years',
            `No, but your teeth will feel tight and uncomfortable 
            only for the first few days after each adjustment`,
            'RM5000++. Orthodontic services will be done and managed by the consultant orthodontist',
        ]   
    },
    {
        id: 'crowns',
        header: 'Crowns & Bridges', 
        name: 'Crowns & Bridges',  
        desc: `A dental crown is used to restore the shape, look and function of a broken tooth. This treatment is
        usually required after RCT or when you have a fractured tooh. A few reasons you would require a dental crown are
        it protects the tooth from further damage and improves aesthetics better than direct fillings. \n\
        A bridge replaces
        missing tooth when you have teeth in front and behind it. You should call and book for an appointment for a bridge
        when adjacent teeth are already heavily restored or when implants are contraindicated. Bridges help improve aesthetics,
        are strong and long lasting, and do not require surgery.`,
        bg: 'rct-bg',
        main: '/assets/crowns/main.jpg',
        hr: '22rem',
        serviceDescriptionId: 'service-description-3',
        workId: 'service-work-3',
        work:
        [
            {
                image:'/assets/crowns/1.png',
            },
            {
                image:'/assets/crowns/2.png',
            },
            {
                image:'/assets/crowns/3.png',
            },
            {
                image:'/assets/crowns/4.png',
            },
            {
                image:'/assets/crowns/5.png',
            },
            {
                image:'/assets/crowns/6.png',
            },
        ],
        q: 
        [
            'How long will a crown last?', 
            'Do I need a crown after RCT?', 
            'What are my other options to a bridge?', 
        ],
        a:
        [
            'Normally they last for many years, depending on the manufacturer',
            'Yes, for all molar (posterior teeth), it is advisable to crown them after the RCT to minimize the risk of tooth fracture',
            'Implants or dentures',
        ]
    },
    {
        id: 'dentures',
        header: 'Dentures', 
        name: 'All natural dentures', 
        desc: `Dentures are artificial teeth and gums used to replace multiple missing teeth that are removable. 
        Unlike implants or bridges, dentures are removable, cheaper and is less invasive of a procedure.
        Be able to chew again!`,
        bg: 'rct-bg',
        main: '/assets/dentures/main.jpg',
        hr: '10rem',
        serviceDescriptionId: 'service-description-4',
        workId: 'service-work-4',
        work:
        [
        ],
        q: 
        [
            'How long will dentures last?', 
            'What are my other options?', 
            'Is it bad to leave dentures in all the time?', 
        ],
        a:
        [
            'On average, 5-10 years',
            'Implants or bridges',
            'Yes, advisable to remove the denture before sleeping',
        ]
    },
    {
        id: 'implants',
        header: 'Implants',    
        name: 'Replace missing tooth',    
        desc: `Implants are titanium screws that will be placed in your bone to act as anchors for a crown.
        You should call in and book for an appointment for implants when you have missing tooth, sufficient bones and healthy
        adjacent teeth.
        Implants help maintain bone level, have 80% chewing efficiency compared to natural teeth and are long lasting.`,
        bg: 'rct-bg',
        main: '/assets/implants/main.jpg',
        hr: '10rem',
        serviceDescriptionId: 'service-description-5',
        workId: 'service-work-5',
        work:
        [
            {
                image:'/assets/implants/1.png',
            },
            {
                image:'/assets/implants/2.png',
            },
            {
                image:'/assets/implants/3.png',
            },
            {
                image:'/assets/implants/4.png',
            },
            {
                image:'/assets/implants/5.png',
            },
            {
                image:'/assets/implants/6.png',
            },
        ],
        q: 
        [
            'How long will the treatment take?', 
            'How long will an implant last?', 
            'What are my other options?', 
        ],
        a:
        [
            'A proper implant require multiple visits depending on the complexity of the case',
            '90-95% of the cases last for more than 10 years',
            'Bridges or dentures',
        ]
    },
    {
        id: 'veneers',
        header: 'Veneers',
        name: 'Get that beautiful smile',
        desc: `Veneers are a thin layer of porcelain/ceramic used to cover the surface of your teeth.
        Veneer treatments are used for aesthetic concerns and closure of gaps between teeth.
        Unlike crowns, veneers are much less invasive.`,
        bg: 'rct-bg',
        main: '/assets/veneers/main.jpg',
        hr: '8rem',
        serviceDescriptionId: 'service-description-6',
        workId: 'service-work-6',
        work:
        [
            {
                image:'/assets/veneers/1.jpg',
            },
            {
                image:'/assets/veneers/2.png',
            },
            {
                image:'/assets/veneers/3.png',
            },
        ],
        q: 
        [
            'How long will a veneer last?', 
            'What are my other options?', 
            'How much does it cost?', 
        ],
        a:
        [
            'According to the American Dental Association, veneers last about 10 years, depending on how the patient takes care of them',
            'Direct filling',
            'Cost depends on the case. Please call the clinic for the pricing',
        ]
    },
    {
        id: 'whitening',
        header: 'Tooth Whitening', 
        name: 'A confidence boost',    
        desc: `Always wanted that pristine-white smile? A beautiful smile can improve your
        appearance and confidence for your personal and professional life. If you have any insecurities
        about your teeth, worry no more as we are always more than happy to help you create your dream smile.`,
        bg: 'rct-bg',
        main: '/assets/whitening/main.jpg',
        hr: '20rem',
        serviceDescriptionId: 'service-description-7',
        workId: 'service-work-7',
        work:
        [
            {
                image:'/assets/whitening/1.jpg',
            },
            {
                image:'/assets/whitening/2.png',
            },
            {
                image:'/assets/whitening/3.png',
            }
        ],
        q: 
        [
            'How long will it last?', 
            'Will my teeth be sensitive after?', 
            'Types of bleaching', 
        ],
        a:
        [
            'On average, 2 to 3 years or as little as 6 months. It varies from person to person',
            'Yes, the sensitivity will last for about a day or two',
            'There are basically 2 ways; clinical chairside bleaching and homekit bleaching',
        ]
    },
    {
        id: 'extractions',
        header: 'Extractions & Surgery',   
        name: 'Got a bad tooth?',    
        desc: `Sometimes, the best choice is to just extract a tooth when all other options are not feasible.
        Most extractions are usually simple and straight-forward.
        However, when a tooth is difficult to extract, we may opt to perform a surgical extraction instead.
        A more common need for minor oral surgery is the wisdom tooth removal.`,
        bg: 'rct-bg',
        main: '/assets/extractions/main.jpg',
        hr: '26rem',
        serviceDescriptionId: 'service-description-8',
        workId: 'service-work-8',
        work:
        [
        ],
        q: 
        [
            'Will surgery be painful?', 
            'How long should I rest for?', 
            'Typical complications after surgery', 
        ],
        a:
        [
            'During the surgery, no but you might develop pain in the first 24 to 48 hours after the surgery',
            'You should expect to rest for at least 2 days after the surgery whereas the healing process might take a bit longer',
            'Infections, swelling or bruising',
        ]
    },
    {
        id: 'fillings',
        header: 'Fillings',  
        name: 'Fix that decay early!',    
        desc: `Fillings are the most common treatment choice for dental decay. Simple and fast.
        Fillings help restore broken teeth back to function and look.`,
        bg: 'rct-bg',
        main: '/assets/fillings/main.png',
        hr: '26rem',
        serviceDescriptionId: 'service-description-9',
        workId: 'service-work-9',
        work:
        [
            {
                image:'/assets/fillings/1.png',
            },
            {
                image:'/assets/fillings/2.png',
            },
            {
                image:'/assets/fillings/3.png',
            },
            {
                image:'/assets/fillings/4.png',
            },
            {
                image:'/assets/fillings/5.png',
            },
            {
                image:'/assets/fillings/6.png',
            },
        ],
        q: 
        [
            'Type of fillings', 
            'What are my other options?', 
            'How much will it cost?', 
        ],
        a:
        [
            'Composite restoration',
            'Crowns for big cavity',
            'Please call the clinic for the pricing',
        ]
    },

]

export default serviceList;