import Home from "./Components/Home";
import Service from "./Components/Service";
import Contact from "./Components/Contact";
import About from "./Components/About";
import Footer from "./Components/Footer";
import { Redirect, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Navigation from "./Components/Navigation/index2";
import Credit from "./Components/Credit";
// import Navigation from './Components/TempNavigation/index'
import "./App.css";

function Routes() {
    return (
        <div>
            <div className="App">
                {/* <Header></Header> */}
                {/* <hr></hr> */}
                <Navigation></Navigation>
                <div id="main-body">
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/services/:service" component={Service} />
                        <Route path="/about" exact component={About} />
                        <Route path="/contact" exact component={Contact} />
                        <Redirect to="/404"></Redirect>
                    </Switch>
                </div>
            </div>
            <Footer></Footer>
            <Credit></Credit>
        </div>
    );
}

export default Routes;
