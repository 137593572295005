import {React, useState, useEffect} from 'react';
import { useHistory } from 'react-router';
import './style.css'

const Work = (props) => {
    const [classState, setClassState] = useState(["work-image","work-image","work-image"]);

    const history = useHistory();

    useEffect(() => {
        setClassState(["work-image","work-image","work-image"]);
        
        let listener = document.addEventListener("scroll", (e) => {
            let pos = document.getElementById(props.animationId)
            if (pos && pos.getBoundingClientRect().top <= window.innerHeight*3/4) {
                setClassState(["work-image-1", "work-image-2", "work-image-3"]);
            }
        });
        return () => {
            document.removeEventListener("scroll", listener);
        };
    }, [history.location.pathname]);

    const renderServices = (work,index) => {
        let i = Math.floor(Math.random() * (2-0+1)) + 0;

        return (
            <div className="col-lg-4 col-md-6 col-sm-12 nopadding" key={index}>
                <div className="work">
                <img className={classState[i]} src={work.image} alt="our work"></img>
                </div>
            </div>
        )
    }

    return (
        <div className="container">
            <div className="row" id={props.animationId}>
                {props.work.map(renderServices)}
            </div>
        </div>
    )
}

export default Work;