import React from "react";
import "./style.css";

const FAQ = (props) => {
  return (
    <div className="FAQs">
      <h2> FAQs</h2>
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="flip-box">
              <div className="flip-box-inner">
                <div className="flip-box-front">
                  <h2>{props.q[0]}</h2>
                </div>
                <div className="flip-box-back">
                  <p>{props.a[0]}</p>
                </div>
              </div>
            </div>
            {/* <div className="FAQ">
              <h2>{props.q[0]}</h2>
              <p>{props.a[0]}</p>
            </div> */}
          </div>
          <div className="col-lg-4">
            <div className="flip-box">
              <div className="flip-box-inner">
                <div className="flip-box-front">
                  <h2>{props.q[1]}</h2>
                </div>
                <div className="flip-box-back">
                  <p>{props.a[1]}</p>
                </div>
              </div>
            </div>
            {/* <div className="FAQ">
              <h2>{props.q[1]}</h2>
              <p>{props.a[1]}</p>
            </div> */}
          </div>
          <div className="col-lg-4">
            <div className="flip-box">
              <div className="flip-box-inner">
                <div className="flip-box-front">
                  <h2>{props.q[2]}</h2>
                </div>
                <div className="flip-box-back">
                  <p>{props.a[2]}</p>
                </div>
              </div>
            </div>
            {/* <div className="FAQ">
              <h2>{props.q[2]}</h2>
              <p>{props.a[2]}</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
