import React from 'react';
import './style.css'

const Reviews = () => {
    return(
        <div className="reviews">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="quotation">
                            &#8221;
                        </div>
                        <div className="review">
                            <p>
                            “Great service! A very patient dentist who provides great recommendations, 
                            Q&A and explanation on what happened to my wisdom tooth”
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="quotation">
                                &#8221;
                        </div>
                        <div className="review">
                            <p>
                            “Excellent service. I have been their patient for a very long time, 
                            and I still always travel all the way from Ipoh for my regular
                            dental check up.”
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="quotation">
                                &#8221;
                        </div>
                        <div className="review">
                            <p>
                            “I've always had an amazing experience here. 
                            The dentists here are very patient and professional. 
                            Great service and the price is very reasonable.”
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reviews