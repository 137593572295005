import { React, useState, useEffect } from "react";
import "./style.css";

const DoctorRight = (props) => {
  const [classState, setClassState] = useState("about-start");

  useEffect(() => {
    let listener = document.addEventListener("scroll", (e) => {
      let pos = document.getElementById(props.animationId)
      if (pos && pos.getBoundingClientRect().top <= window.innerHeight*3/4) {
        setClassState("right-doctor-slide");
      }
    });
    return () => {
      document.removeEventListener("scroll", listener);
    };
  });

  const renderFacts = (facts, index) => {
    return(
      <li className="specialisation-body" style={{textAlign:"left"}} key={index}>{facts}</li>
    )
  }

  return (
    <div className="DoctorProfileSection">
      <div className="row row-flex reverse-flex">
        <div className="col-lg-8 col-flex-item divider">
          <h2>{props.name}</h2>
          <p className="specialisation">{props.specialisation}</p>
          <hr
            style={{
              width: '4rem',
              marginTop: "1%",
              marginLeft: "0%",
              border: "0",
              borderBottom: "2px solid grey",
              borderBottomColor: "grey",
            }}
          />
          <ul style={{paddingLeft: "15px"}}>
            <p>
              {props.facts.map(renderFacts)}
            </p>
          </ul>
        </div>
        <div className="col-lg-4 col-flex-item divider">
          <img
            alt="doctor"
            src={props.image}
            className={classState}
            id={props.animationId}
            style={{
              width: "100%",
              height: "100%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          ></img>
        </div>
      </div>
    </div>
  );
};

export default DoctorRight;
