import React, { useEffect, useState } from "react";
import "./style.css";

const OurStory = () => {
  const [classState, setClassState] = useState("about-start");

  useEffect(() => {
    let listener = document.addEventListener("scroll", (e) => {
      let pos = document.getElementById('about-story')
      if (pos && pos.getBoundingClientRect().top <= window.innerHeight*3/4) {
        setClassState("about-slide");
      }
    });
    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, []);

  return (
    <div className="OurStory">
      <div className="row row-flex">
        <div className="col-lg-7 col-flex-item divider">
          <h2>How it all started...</h2>
          <hr
            style={{
              width: "6rem",
              marginTop: "0%",
              marginLeft: "0%",
              border: "0",
              borderBottom: "2px solid grey",
              borderBottomColor: "#005163",
            }}
          />
          <p>
            Ng Dental Surgery was established in 1986 by Dr. Ng and Dr. See who
            graduated from the reputable and prestigious University of Malaya
            (UM). They were then later joined by a consultant orthodontist, Dr.
            Chia. Today, the clinic is fully equipped with the latest equipments
            ranging from 3D tooth scanning machine to full mouth radiography.
          </p>
        </div>
        <div className="col-lg-5 col-flex-item divider">
          <img
            alt="our story"
            src="assets/about/1.jpg"
            className={classState}
            id="about-story"
            style={{
              width: "100%",
              height: "100%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          ></img>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
