import "./style.css";

const Credit = () => {
  return (
    <div className="credit">
      <div className="container">
        <div className="row justify-content-left">
            <p>Copyright &copy; 2021 Ng Dental Surgery Klang</p>
        </div>
      </div>
    </div>
  );
};

export default Credit;
