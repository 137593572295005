import {React, useState, useEffect} from 'react';
import services from "./list-items";
import { useHistory } from "react-router-dom"; 
import './style.css'

const Services = () => {
    const [classState, setClassState] = useState(["services-image","services-image","services-image"]);

    useEffect(() => {
        let listener = document.addEventListener("scroll", (e) => {
            let pos = document.getElementById('home-services')
            if (pos && pos.getBoundingClientRect().top <= window.innerHeight*3/4) {
                setClassState(["services-image-1", "services-image-2", "services-image-3"]);
            }
        });
        return () => {
            document.removeEventListener("scroll", listener);
        };
    }, []);

    const history = useHistory();
    const serviceOnClickHandler = (route) => {
        history.push(route)
    }

    const renderServices = (service,index) => {
        let i = Math.floor(Math.random() * (2-0+1)) + 0;

        return (
            <div className="col-lg-4 col-md-6 col-sm-12 nopadding" key={index}>
                <div className="services" onClick={e=>{serviceOnClickHandler(service.route)}}>
                <img className={classState[i]} src={service.image} alt="service"></img>
                <div className="services-title">
                    <h3>
                        {service.title}
                    </h3>
                </div>
                </div>
            </div>
        )
    }

    return (
        <div className="container">
            <div className="row" id='home-services'>
                {services.map(renderServices)}
            </div>
        </div>
    )
}

export default Services;