import React from 'react';
import './style.css'

const Facilities = () => {
    return(
        <div className="facilities">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="tool">
                            <i className="fas fa-tools" style={{paddingTop:"15px"}}></i>
                        </div>
                        <div className="facility">
                            <div className="facility-title">Infection Control & Sterilization</div>
                            <p>
                                Ng Dental uses two autoclaving machines. All instruments are autoclaved in a
                                special sealed sterilization pouches, and opened just prior to the start of treatment
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="tool">
                            <i className="fas fa-tools" style={{paddingTop:"15px"}}></i>
                        </div>
                        <div className="facility">
                            <div className="facility-title">Digital X-ray</div>
                            <p>
                                Advanced digital radiology with the use of computerised sensors is used to capture the images
                                of the teeth in order to minimize the exposure to radiation. Digital x-ray includes 3d images,
                                panaromic(OPG), lateral skull, etc. of the teeth
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="tool">
                            <i className="fas fa-tools" style={{paddingTop:"15px"}}></i>
                        </div>
                        <div className="facility">
                        <div className="facility-title">Digital Intraoral Camera</div>
                            <p>
                                Capturing images with intraoral cameras is painless and there is no radiation exposure. It also
                                eliminates any confusion between dentist and patient when discussing the treatment plan.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="facility-image">
                            <img src="assets/facilities/intraoral.jpeg" alt="infection control" width="100%"></img>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="facility-image">
                            <img src="assets/facilities/xray.jpeg" alt="digital x-ray" width="100%"></img>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="facility-image">
                            <img src="assets/facilities/infection.jpeg" alt="intraoral" width="100%"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Facilities