import React from "react";
import { useHistory } from "react-router";
import "./style.css";

const Footer = () => {

  const history = useHistory();
  
  const footerOnClickHandler = (route) => {
    window.scrollTo(0,0);
    history.push(route);
  }

  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h4>
              Services
            </h4>
            <div className="row">
              <div className="col-lg-6">
                <p className="footer-service" onClick={e=>{footerOnClickHandler("/services/rct")}}>
                  RCT
                </p>
                <p className="footer-service" onClick={e=>{footerOnClickHandler("/services/braces")}}>
                  Braces
                </p>
                <p className="footer-service" onClick={e=>{footerOnClickHandler("/services/crowns")}}>
                Crowns & Bridges
                </p>
                <p className="footer-service" onClick={e=>{footerOnClickHandler("/services/dentures")}}>
                Dentures
                </p>
                <p className="footer-service" onClick={e=>{footerOnClickHandler("/services/implants")}}>
                  Implants
                </p>
                <p className="footer-service" onClick={e=>{footerOnClickHandler("/services/veneers")}}>
                  Veneers
                </p>
                <p className="footer-service" onClick={e=>{footerOnClickHandler("/services/whitening")}}>
                  Whitening
                </p>
              </div>
              <div className="col-lg-6">
                <p className="footer-service" onClick={e=>{footerOnClickHandler("/services/extractions")}}>
                  Extractions & Surgery
                </p>
                <p className="footer-service" onClick={e=>{footerOnClickHandler("/services/fillings")}}>
                  Fillings
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <h4>
              Office
            </h4>
            <p>
            Klinik Pergigian Ng, 2578A, 
            <br/>
            Persiaran Raja Muda Musa,
            <br/>
            Taman Sri Pesona,
            <br/>
            41100 Klang, Selangor
            </p>
            <a href="tel:+60333721099">
              <p>
                Call 03-3372 1099
              </p>
            </a>
            {/* <a href="https://wa.me/60163349669"
            target="_blank" rel="noopener noreferrer">
              <p>
              Text 012-345 6789
              </p>
            </a> */}
          </div>
          <div className="col-lg-3">
            <h4>
              Hours
            </h4>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="day">
                        <p>
                          Monday
                          <br/>
                          Tuesday
                          <br/>
                          Wednesday
                          <br/>
                          Thursday
                          <br/>
                          Friday
                          <br/>
                          Saturday
                          <br/>
                          Sunday/PH  
                          <br/>
                          Lunch time
                        </p>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div className="time">
                        <p>
                            9AM - 5PM
                            <br/>
                            9AM - 5PM
                            <br/>
                            9AM - 5PM
                            <br/>
                            9AM - 5PM
                            <br/>
                            9AM - 5PM
                            <br/>
                            9AM - 5PM
                            <br/>
                            Closed
                            <br/>
                            1PM - 2PM
                        </p>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
