import React, { useEffect, useState } from "react";
import "./style.css";

const Nurses = () => {
  const [classState, setClassState] = useState("nurses-start");

  useEffect(() => {
    let listener = document.addEventListener("scroll", (e) => {
      let pos = document.getElementById('nurses-story')
      if (pos && pos.getBoundingClientRect().top <= window.innerHeight*3/4) {
        setClassState("nurses-slide");
      }
    });
    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, []);

  return (
    <div className="nurses">
      <div className="row row-flex">
        <div className="col-lg-7 col-flex-item divider">
          <h2>And we couldn't have done it without them</h2>
          <hr
            style={{
              width: "6rem",
              marginTop: "0%",
              marginLeft: "0%",
              border: "0",
              borderBottom: "2px solid grey",
              borderBottomColor: "#005163",
            }}
          />
          <p>
            And of course, there is a team of dedicated and well trained dental assistants to help the dentists at work. 
            Besides helping the dentists with dental treatment procedures, they are also in charge of managing the patients'
            appointments. These dental assistants have been around for as long as the dentists have and are well experienced.
          </p>
        </div>
        <div className="col-lg-5 col-flex-item divider">
          <img
            alt="our nurses"
            src="assets/about/2.jpg"
            className={classState}
            id="nurses-story"
            style={{
              width: "100%",
              height: "100%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          ></img>
        </div>
      </div>
    </div>
  );
};

export default Nurses;
